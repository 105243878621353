import { ETelemetryEventNames } from './type';
import { telemetry } from '@my-account/tools';

type TrackProperties = {
  action: string;
  label: string;
  clickData?: Record<string, unknown>;
};

export const track = (name: ETelemetryEventNames, properties: TrackProperties): void => {
  telemetry.track(name, properties);
};
