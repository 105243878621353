import { request } from './request';
import { Account } from '@my-account/account';
import { UserProfileType } from './types';

export const fetchAccounts = () => {
  return request<Account[]>('/api/account');
};

export const fetchUserProfile = () => {
  return request<UserProfileType | Record<string, never>>('/api/contact');
};
