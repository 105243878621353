import { track } from 'telemetry';
import { ETelemetryActionsAndLabels, ETelemetryEventNames } from 'telemetry/type';
import { NavDetails } from './type';
import { navigationTree } from './navConfigs';

export const trackClickEventInNavigation = (buttonName: string): void => {
  track(ETelemetryEventNames.MyAccountNavBar, {
    action: ETelemetryActionsAndLabels.Click,
    label: ETelemetryActionsAndLabels.Click,
    clickData: { buttonName },
  });
};

export const isPathnameMatch = (currentPathname: string, matcher: string): boolean => {
  // Pass if pathname include below cases:
  // 1. ends with a slash
  // 2. exact match ignore case
  return new RegExp(`^${matcher}\/?\$`, 'i').test(`${currentPathname}`);
};

export const getTopActiveMenu = (isAccountView): NavDetails => {
  const locationPathname = location.pathname;
  return navigationTree(isAccountView).find((navigation) => {
    const { matcher } = navigation;
    return matcher && isPathnameMatch(locationPathname, matcher);
  });
};
