import styled from '@emotion/styled';
import { mediaQueries } from 'styles/variables';

export const SoloMobileSideNavWrapper = styled.div`
  [data-flx-comp='Navigation.Primary.Dialog'] {
    margin-top: 73px !important;
  }

  [data-flx-comp='Navigation.TopBar'] {
    height: 73px !important;
  }
`;
export const NavigationWrapper = styled.div`
  .flx-container {
    align-items: center;

    .flx-navbar__logo {
      height: 49px;
    }

    .flx-navbar--settings {
      max-width: 46%;
      align-items: center;
      .my-account-account-selector {
        overflow: hidden;
        ${mediaQueries.mediumUp} {
          overflow: auto;
        }
      }

      .nav-selector {
        margin-right: 1.6rem;
        ${mediaQueries.mediumDown} {
          display: none; // hide account selector on mobile when feature flag is on
        }
        .form-group {
          margin: 0;

          &__label-group {
            display: none;
            ${mediaQueries.mediumUp} {
              display: flex;
            }
          }

          > div > div {
            flex: auto;
            ${mediaQueries.mediumUp} {
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }

  .my-account-nav-solo-account {
    height: 73px;
    display: flex;
    align-items: center;

    .flx-navbar__logo {
      height: 65px;
    }
  }

  .my-account-side-nav-mobile-solo-account {
    a {
      height: 65px;
    }
  }

  .my-account-side-nav-mobile {
    a {
      height: 49px;
    }
  }
`;

export const MobileSelectWrapper = styled.div`
  display: block;
  ${mediaQueries.mediumUp} {
    display: none;
  }
`;

export const MobileStyleWrapper = styled.div`
  .hide-label {
    label {
      display: none;
    }
  }
  ${mediaQueries.mediumUp} {
    .hide-label {
      label {
        display: block;
      }
    }
  }

  .my-account-account-selector-mobile {
    border: none;
    background-color: #f9f9f9;
    color: #8241aa;
    text-align: right;
  }
  ${mediaQueries.mediumDown} {
    .svg-icon {
      color: #8241aa;
    }
    .my-account-account-selector-mobile:disabled ~ span {
      .svg-icon {
        color: #cccccc;
      }
    }
  }
`;
