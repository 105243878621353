import styled from '@emotion/styled';
import { mediaQueries } from 'styles/variables';

export const UserProfileWrapper = styled.div`
  .user-profile-container {
    .flx-navbar__menu-link {
      height: 49px;
      display: flex;
      align-items: center;
    }
  }
  .svg-icon-userProfile {
    height: 20px;
    width: 20px;
    margin-right: 0;

    ${mediaQueries.largeUp} {
      margin-right: 0.4rem;
    }
  }

  .flx-avatar {
    margin-right: 0;

    ${mediaQueries.largeUp} {
      margin-right: 0.6rem;
    }
  }

  .svg-icon-caret,
  .user-profile-text {
    display: none;
    ${mediaQueries.largeUp} {
      display: block;
      max-width: 21rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ${mediaQueries.xLargeUp} {
      max-width: 28rem;
    }
  }
`;
