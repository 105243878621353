import { Avatar, CaretIcon, Navigation, ThemeProvider } from '@myob/myob-widgets';
import myAccountLogo from 'assets/images/myAccountLogo.svg';
import myAccountSoloLogo from 'assets/images/myAccountSoloLogo.svg';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import React, { useEffect, useState } from 'react';
import { navigationTree } from './navConfigs';
import { getTopActiveMenu, trackClickEventInNavigation } from './NavigationHelper';
import { NavClassNames, NavDetails } from './type';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { MobileSelectWrapper, NavigationWrapper, SoloMobileSideNavWrapper } from './styles';
import { navigateToUrl } from 'single-spa';
import { NavSelector } from './NavSelector';
import UserProfile from './components/UserProfile';
import { webVitals } from '@my-account/tools';
import { useAccountSelectorState } from '@my-account/account';
import { productFeatureFlag } from '../../configs/FeatureFlags';
import { settingTree } from './components/UserProfile/userProfileConfigs';

webVitals.sendReportToGtm();

const brand = (isAccountView, isSoloAccount) => {
  const homeUrl = isAccountView ? '/account' : '/';
  return (
    <Navigation.Brand url={homeUrl} width="100%">
      {/* Only for tracking meaningful label for google-analytics */}
      <span style={{ display: 'none' }}>MyAccount_Logo</span>
      {isSoloAndUnoThemeEnabled(isSoloAccount) ? (
        <img src={myAccountSoloLogo} alt="My account solo logo" />
      ) : (
        <img src={myAccountLogo} alt="My account logo" />
      )}
    </Navigation.Brand>
  );
};

const handleOnNavigationClick = (event: React.MouseEvent) => {
  const target = event.target as HTMLElement;
  let menuItemName;
  let targetParent = target;
  while (targetParent != null) {
    if (targetParent.classList.contains(NavClassNames.NavWrapper)) {
      menuItemName = '';
      break;
    }
    if (targetParent.classList.contains(NavClassNames.NavItem)) {
      menuItemName = targetParent.firstChild.textContent;
      break;
    }
    targetParent = targetParent.parentElement;
  }
  const classList = target.closest('a')?.parentElement.classList;
  if (menuItemName) {
    const formattedMenuItemName = upperFirst(camelCase(menuItemName));
    trackClickEventInNavigation(formattedMenuItemName);
  }
  if (classList && classList.contains(NavClassNames.NavLink)) {
    const url = target.closest('a').getAttribute('href');
    url && navigateToUrl(url);
    event.preventDefault();
  }
};

const handleOnMobileNavigationTap = (event: React.MouseEvent) => {
  const target = event.target as HTMLElement;
  const menuItemName = target.textContent;
  if (menuItemName) {
    const formattedMenuItemName = upperFirst(camelCase(menuItemName));
    trackClickEventInNavigation(formattedMenuItemName);
  }
  const url = target.closest('a').getAttribute('href');
  url && navigateToUrl(url);
  event.preventDefault();
};

const handleSubMenuItemTap = (closeMenu, event) => {
  closeMenu();
  handleOnMobileNavigationTap(event);
};

const handleOnKeyPress = (event) => {
  if (event.key === 'Enter') {
    handleOnNavigationClick(event);
  }
};

const generateSecondaryMenuItems = (navItems) => {
  return navItems
    .filter((item) => !item.invisible)
    .map((item) => (
      <Navigation.MenuLink
        key={item.key}
        className={`${NavClassNames.NavItem} ${NavClassNames.NavLink}`}
        label={item.label}
        url={item.url}
        disabled={item.disabled}
      />
    ));
};

export type NavigationProps = {
  isAccountView?: boolean;
};

function hasChildren(navItem: NavDetails) {
  return navItem.items && navItem.items.some((item) => !item.invisible);
}

function isSoloAndUnoThemeEnabled(isSoloAccount: boolean) {
  return isSoloAccount && productFeatureFlag.isNewUnoDesignEnabled();
}

const Nav: React.FC<NavigationProps> = ({ isAccountView }) => {
  const [topActiveMenu, setTopActiveMenu] = useState<NavDetails>();
  const [{ isLoading: isAccountListLoading, isSoloAccount }] = useAccountSelectorState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isAccountViewProductsPages = /^\/account\/products(\/|$)/.test(location.pathname);
  const isContactDetailsPage = /^\/account\/contact-details(\/|$)/.test(location.pathname);
  const isSupportDetailsPage = /^\/account\/support\/support-requests\/(\w+(-\w+)*)/.test(location.pathname);
  const isPremiumSupportBookingPage = /^\/account\/support\/priority-support-booking\/(\w+(-\w+)*)/.test(
    location.pathname,
  );
  const [fullName, setFullName] = useState('Anonymous');
  const isMobile = windowWidth < 768;

  useEffect(() => {
    const handleLocationChange = () => {
      const activeMenu = getTopActiveMenu(isAccountView);
      setTopActiveMenu(activeMenu);
    };

    window.addEventListener('single-spa:routing-event', handleLocationChange);
    return () => window.removeEventListener('single-spa:routing-event', handleLocationChange);
  }, [isAccountView]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const generateNavTree = () => {
    return navigationTree(isAccountView, isSoloAccount).map((topNav) => {
      if (hasChildren(topNav)) {
        return (
          !isAccountListLoading && (
            <Navigation.Menu
              key={topNav.key}
              className={`${NavClassNames.NavItem} nav-${topNav.classSelector}`}
              label={topNav.label}
              icon={<CaretIcon title={`icon-${topNav.classSelector}`} />}
              items={generateSecondaryMenuItems(topNav.items)}
              active={topActiveMenu?.label === topNav.label}
            />
          )
        );
      }
      return (
        <Navigation.Link
          key={topNav.key}
          className={`${NavClassNames.NavLink} ${topNav.classSelector}`}
          label={topNav.label}
          url={topNav.url}
          active={topActiveMenu?.label === topNav.label}
          disabled={topNav.disabled}
        />
      );
    });
  };

  const generateMobileNavTree = () => {
    return navigationTree(isAccountView, isSoloAccount).map((navItem) => {
      return {
        menuLink: (
          <Navigation.Button
            prefixAccessory={navItem.icon != null ? <navItem.icon /> : null}
            as="a"
            color="foregroundDefault"
            href={!hasChildren(navItem) ? navItem.url : null}
            onClick={handleOnMobileNavigationTap}
          >
            {navItem.label}
          </Navigation.Button>
        ),
        subMenuTitle: hasChildren(navItem) ? navItem.label : null,
        subMenuItems: hasChildren(navItem)
          ? ({ closeMenu }) => (
              <>
                {navItem.items
                  .filter((item) => !item.invisible)
                  .map((item) => (
                    <Navigation.SubMenuItem
                      key={'mobile' + item.label}
                      onClick={(event) => handleSubMenuItemTap(closeMenu, event)}
                      href={item.url}
                      disabled={item.disabled}
                    >
                      {item.label}
                    </Navigation.SubMenuItem>
                  ))}
              </>
            )
          : null,
      };
    });
  };

  const generateMobileSettingTree = ({ closeMenu }) => {
    return settingTree(isAccountView).map((settingItem) => {
      return (
        <Navigation.SubMenuItem
          key={settingItem.key}
          onClick={(event) => {
            closeMenu();
            settingItem.onClick(event);
          }}
          icon={settingItem.icon != null ? <settingItem.icon /> : null}
        >
          {settingItem.label}{' '}
        </Navigation.SubMenuItem>
      );
    });
  };

  const tertiary = [
    {
      button: <Avatar type="user" color="light-grey" name={fullName} />,
      drawerTitle: 'User account',
      drawerContent: generateMobileSettingTree,
    },
  ];

  const sideNavComponent = (
    <Navigation.SideNav
      className={
        isSoloAndUnoThemeEnabled(isSoloAccount)
          ? 'my-account-side-nav-mobile-solo-account'
          : 'my-account-side-nav-mobile'
      }
      brand={{
        url: isAccountView ? '/account' : '/',
        topMenu: (
          <a href={isAccountView ? '/account' : '/'}>
            {isSoloAndUnoThemeEnabled(isSoloAccount) ? (
              <img src={myAccountSoloLogo} alt="My account solo logo" />
            ) : (
              <img src={myAccountLogo} alt="My account logo" />
            )}
          </a>
        ),
      }}
      primary={generateMobileNavTree()}
      tertiary={tertiary}
    />
  );

  const shouldShowAccountSelector = () => {
    return !(
      isContactDetailsPage ||
      isSupportDetailsPage ||
      isAccountViewProductsPages ||
      !isAccountView ||
      isPremiumSupportBookingPage
    );
  };

  const handleNavigationClassName = (isSoloAccount: boolean) => {
    return isSoloAndUnoThemeEnabled(isSoloAccount) ? 'my-account-nav-solo-account' : 'my-account-nav';
  };

  return (
    <ThemeProvider>
      <CacheProvider
        value={createCache({
          key: 'my-account-nav',
        })}
      >
        <NavigationWrapper
          tabIndex={0}
          onKeyDown={handleOnKeyPress}
          onClick={handleOnNavigationClick}
          className={NavClassNames.NavWrapper}
        >
          {!isMobile ? (
            <Navigation
              className={handleNavigationClassName(isSoloAccount)}
              fluid
              brand={brand(isAccountView, isSoloAccount)}
              primary={generateNavTree()}
              settings={
                shouldShowAccountSelector()
                  ? [
                      <NavSelector key="selector-in-nav" />,
                      <UserProfile key="user-profile" isAccountView={isAccountView} />,
                    ]
                  : [<UserProfile key="user-profile" isAccountView={isAccountView} />]
              }
            />
          ) : (
            <>
              <UserProfile isAccountView={isAccountView} isMobile={isMobile} setFullName={setFullName} />
              {isSoloAndUnoThemeEnabled(isSoloAccount) ? (
                <SoloMobileSideNavWrapper>{sideNavComponent}</SoloMobileSideNavWrapper>
              ) : (
                sideNavComponent
              )}
              <>
                {shouldShowAccountSelector() && (
                  <div>
                    <MobileSelectWrapper>
                      <NavSelector isMobile={isMobile} />
                    </MobileSelectWrapper>
                  </div>
                )}
              </>
            </>
          )}
        </NavigationWrapper>
      </CacheProvider>
    </ThemeProvider>
  );
};

export default Nav;
